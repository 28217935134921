export const ICON = {
    rocket: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="29"
            fill="none"
            viewBox="0 0 28 29"
        >
            <g fill="#fff" clipPath="url(#clip0_2437_1023)">
                <path d="M7.92 23.063c-.298.488-.864.763-1.252.854-.656.122-1.282.183-1.76.58-.149.122-.387.03-.417-.153-.09-.58.06-1.22.328-1.616-.12 0-.209.03-.328.091-.209.092-.417-.091-.388-.304.18-.671.627-1.556 1.313-1.952L4.073 19.19c-2.206.488-3.578 3.171-4.055 5.001a.615.615 0 00.864.702c.239-.122.478-.183.746-.183-.596.915-.984 2.409-.746 3.781.09.458.627.671.985.366C3 27.912 4.46 27.82 5.982 27.515c1.133-.244 2.953-1.25 3.4-2.958-.12-.091-.239-.213-.358-.335l-1.103-1.159zM22.504 15.775C26.977 10.591 28.111 3.15 27.991.893a.886.886 0 00-.268-.61.996.996 0 00-.596-.274c-2.207-.122-9.484 1.006-14.554 5.61l-1.61-.609a4.474 4.474 0 00-5.01 1.342L3.148 9.737c-.388.488-.209 1.22.358 1.433l3.579 1.342a44.685 44.685 0 00-2.088 3.965c-.268.64-.15 1.372.328 1.89l4.712 4.819a1.61 1.61 0 001.85.335 36.142 36.142 0 003.876-2.135l1.312 3.66c.209.58.925.793 1.402.366l3.28-2.867c1.432-1.25 1.969-3.293 1.313-5.123l-.567-1.647zm-1.938-4.086a2.8 2.8 0 01-4.056 0 2.955 2.955 0 010-4.148 2.8 2.8 0 014.056 0c1.133 1.159 1.133 3.02 0 4.148z"></path>
            </g>
            <defs>
                <clipPath id="clip0_2437_1023">
                    <path fill="#fff" d="M0 0H28V29H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    threeDots: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="47"
            height="33"
            fill="none"
            viewBox="0 0 47 33"
        >
            <g fill="#fff" clipPath="url(#clip0_2437_1027)">
                <path d="M45.26 14.747H1.74C.78 14.747 0 15.531 0 16.5c0 .968.779 1.752 1.74 1.752h43.52c.961 0 1.74-.784 1.74-1.752 0-.969-.779-1.753-1.74-1.753zM45.26 29.495H1.74c-.961 0-1.74.786-1.74 1.753C0 32.215.779 33 1.74 33h43.52c.961 0 1.74-.785 1.74-1.752s-.779-1.753-1.74-1.753zM1.74 3.505h43.52c.961 0 1.74-.786 1.74-1.753C47 .785 46.221 0 45.26 0H1.74C.78 0 0 .785 0 1.752s.779 1.753 1.74 1.753z"></path>
            </g>
            <defs>
                <clipPath id="clip0_2437_1027">
                    <path fill="#fff" d="M0 0H47V33H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    times: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="none"
            viewBox="0 0 36 36"
        >
            <path
                fill="#fff"
                d="M20.39 18L35.488 2.9c.683-.683.683-1.707 0-2.388-.683-.683-1.707-.683-2.389 0L18 15.61 2.9.511C2.22-.17 1.195-.17.512.512c-.683.684-.683 1.707 0 2.389l15.1 15.1-15.1 15.099c-.683.682-.683 1.706 0 2.388.34.34.768.511 1.195.511.427 0 .853-.17 1.195-.511l15.1-15.1 15.098 15.1c.341.34.768.511 1.195.511.427 0 .853-.17 1.195-.511.683-.683.683-1.707 0-2.389L20.39 18z"
            ></path>
        </svg>
    ),
    rightRoundArrow: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <circle cx="8" cy="8" r="8" fill="#1F1F45"></circle>
            <g clipPath="url(#clip0_964_3257)">
                <path
                    fill="#EFA"
                    d="M11 7.5l-.344-.381L6.943 3 6 3.763 9.37 7.5 6 11.237l.943.763 3.713-4.119L11 7.5z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_964_3257">
                    <path
                        fill="#fff"
                        d="M0 0H5V9H0z"
                        transform="translate(6 3)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    ),
    instagram: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            fill="none"
            viewBox="0 0 18 19"
        >
            <path
                fill="#fff"
                d="M12 9.712a3 3 0 10-6 0 3 3 0 006 0zm1.621 0a4.622 4.622 0 11-9.243 0 4.622 4.622 0 019.243 0zM5.275 4.907a1.08 1.08 0 11-2.16.002 1.08 1.08 0 012.16-.002zm7.36 12.13c.879-.04 1.355-.185 1.672-.309.42-.163.72-.358 1.035-.673a2.77 2.77 0 00.674-1.034c.123-.317.27-.795.31-1.672.043-.949.052-1.233.052-3.636 0-2.404-.01-2.688-.053-3.637-.04-.878-.186-1.354-.31-1.671a2.797 2.797 0 00-.673-1.036 2.768 2.768 0 00-1.035-.673c-.317-.124-.794-.27-1.671-.31-.95-.043-1.234-.052-3.636-.052-2.403 0-2.688.01-3.637.053-.878.04-1.354.186-1.671.31-.42.162-.72.358-1.036.672-.315.315-.51.615-.673 1.036-.124.317-.27.794-.31 1.67-.044.95-.052 1.234-.052 3.638 0 2.403.008 2.687.052 3.636.04.878.187 1.355.31 1.671.163.421.359.72.673 1.035.315.315.615.51 1.036.673.316.124.794.27 1.67.31.95.044 1.234.052 3.638.052 2.403 0 2.688-.009 3.636-.052zM12.71.768c.958.044 1.612.195 2.184.418.592.23 1.094.538 1.595 1.038.5.5.809 1.001 1.039 1.594.222.572.374 1.226.418 2.184.044.96.054 1.267.054 3.71 0 2.445-.01 2.752-.055 3.711-.044.96-.196 1.612-.418 2.185a4.39 4.39 0 01-1.038 1.594c-.502.5-1.002.808-1.595 1.038-.573.222-1.226.374-2.184.418-.96.044-1.267.055-3.71.055-2.446 0-2.752-.01-3.71-.055-.96-.044-1.613-.196-2.185-.418A4.417 4.417 0 011.51 17.2c-.5-.5-.808-1.003-1.039-1.594-.222-.573-.375-1.227-.418-2.185C.01 12.462 0 12.156 0 9.712s.01-2.751.054-3.71c.044-.96.196-1.612.418-2.185a4.417 4.417 0 011.039-1.594c.5-.5 1.002-.808 1.593-1.038C3.677.962 4.331.81 5.289.767 6.248.723 6.554.712 8.999.712c2.444 0 2.751.01 3.71.055z"
            ></path>
        </svg>
    ),
    facebook: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="19"
            fill="none"
            viewBox="0 0 9 19"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M5.795 18.712V9.706H8.65l.127-1.414L9 6.108H5.941V4.664c0-.695.492-.86.845-.86h2.16V.711H5.97c-3.308 0-4.05 2.284-4.05 3.77v1.62H0v3.61h1.943v9h3.852z"
                clipRule="evenodd"
            ></path>
        </svg>
    ),
    linkedIn: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            fill="none"
            viewBox="0 0 20 19"
        >
            <path
                fill="#fff"
                d="M4.427 18.712V6.566H.247v12.146h4.18zM2.337 4.908c1.458 0 2.365-.932 2.365-2.098C4.675 1.62 3.795.712 2.365.712.935.712 0 1.619 0 2.81c0 1.166.907 2.098 2.31 2.098h.027zM6.74 18.711h4.18V11.93c0-.363.027-.726.137-.985.303-.726.99-1.476 2.146-1.476 1.512 0 2.117 1.113 2.117 2.746v6.497h4.18v-6.964c0-3.73-2.061-5.466-4.812-5.466-2.254 0-3.245 1.219-3.795 2.046h.028V6.567H6.74c.055 1.137 0 12.145 0 12.145H6.74z"
            ></path>
        </svg>
    ),
    rightArrow: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="9"
            fill="none"
            viewBox="0 0 5 9"
        >
            <g clipPath="url(#clip0_936_13839)">
                <path
                    fill="#fff"
                    d="M5 4.5l-.343-.381L.943 0 0 .763 3.37 4.5 0 8.237.943 9l3.714-4.119L5 4.5z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_936_13839">
                    <path fill="#fff" d="M0 0H5V9H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    download: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="23"
            fill="none"
            viewBox="0 0 25 23"
        >
            <g fill="#fff" clipPath="url(#clip0_1_13093)">
                <path d="M11.274 16.287c.276.384.733.636 1.248.636.355 0 .681-.12.94-.32.054-.041.105-.083.156-.13l6.4-6.263a1.467 1.467 0 000-2.11 1.546 1.546 0 00-2.156 0l-3.815 3.734V1.493C14.047.67 13.366 0 12.522 0c-.844 0-1.525.667-1.525 1.493V11.8l-3.85-3.767a1.546 1.546 0 00-2.155 0 1.467 1.467 0 000 2.11l6.28 6.146.003-.003z"></path>
                <path d="M23.475 13.94c-.84 0-1.526.668-1.526 1.494v4.264c0 .175-.147.32-.326.32H3.377a.325.325 0 01-.326-.32v-4.264c0-.823-.682-1.493-1.526-1.493-.843 0-1.525.667-1.525 1.492v4.265C0 21.518 1.514 23 3.375 23h18.25C23.487 23 25 21.518 25 19.698v-4.264c0-.823-.681-1.493-1.525-1.493z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1_13093">
                    <path fill="#fff" d="M0 0H25V23H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    question: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="none"
            viewBox="0 0 26 26"
        >
            <path
                fill="#1F1F45"
                d="M13 0C5.834 0 0 5.851 0 13.036c.71 17.286 25.28 17.286 26 0C26 5.85 20.165 0 13 0zm-.276 5.16c1.626.028 1.626 2.45 0 2.48-1.627-.03-1.627-2.451 0-2.48zm3.232 15.743h-3.942V11.03h-1.971V9.053h3.942v9.883h1.972v1.967z"
            ></path>
        </svg>
    ),
};
