import { lazy } from "react";
import { HOME, MASTERY, STRATEGY, RELIABILITY, ULTRABACKUPHOME, VISION, PRICE, WHITEMARK, HISTORY, CONTACT } from "constant";
import path from "path";

export const routes = [
    {
        path: HOME,
        component: lazy(() => import("containers/Home/Home")),
    },
    {
        path: MASTERY,
        component: lazy(() => import("containers/Mastery/Mastery")),
    },
    {
        path: STRATEGY,
        component: lazy(() => import("containers/Strategy/Strategy")),
    },
    {
        path: RELIABILITY,
        component: lazy(() => import("containers/Reliability/Reliability")),
    },
    {
        path: ULTRABACKUPHOME,
        component: lazy(() => import("containers/UltraBackupHome/UltraBackupHome")),
    },
    {
        path: VISION,
        component: lazy(() => import("containers/Vision/Vision")),
    },
    {
        path: PRICE,
        component: lazy(() => import("containers/Price/Price")),
    },
    {
        path: WHITEMARK,
        component: lazy(() => import("containers/WhiteMark/WhiteMark")),
    },
    {
        path: HISTORY,
        component: lazy(() => import("containers/History/History")),
    },
    {
        path: CONTACT,
        component: lazy(() => import("containers/Contact/Contact")),
    }
];
