import axios, {
    type AxiosRequestConfig,
    type AxiosInstance,
    type AxiosResponse,
    type AxiosError,
} from "axios";

export class HttpClient {
    private static _instance: AxiosInstance;

    static get instance(): AxiosInstance {
        if (!HttpClient._instance) {
            HttpClient._instance = HttpClient.create();
        }

        return HttpClient._instance;
    }

    private static create(): AxiosInstance {
        const requestConfig: AxiosRequestConfig = {
            baseURL: `${process.env.REACT_APP_API_URL}/api/`,
            withCredentials: true,
        };
        const instance = axios.create(requestConfig);

        return instance;
    }

    static post<T>(endpoint: string, data?: unknown): Promise<T> {
        return HttpClient.instance
            .post(endpoint, data, {
                headers: HttpClient.getHeader(),
            })
            .then(
                (res: AxiosResponse): Promise<T> =>
                    HttpClient.handleAxiosResponse<T>(res),
            )
            .catch(
                (err: AxiosError): Promise<T> =>
                    HttpClient.handleAxiosError<T>(err),
            );
    }

    static put<T>(endpoint: string, data?: unknown): Promise<T> {
        return HttpClient.instance
            .put(endpoint, data, {
                headers: HttpClient.getHeader(),
            })
            .then(
                (res: AxiosResponse): Promise<T> =>
                    HttpClient.handleAxiosResponse<T>(res),
            )
            .catch(
                (err: AxiosError): Promise<T> =>
                    HttpClient.handleAxiosError(err),
            );
    }

    static get<T>(endpoint: string, params?: unknown): Promise<T> {
        return HttpClient.instance
            .get(endpoint, {
                params: {
                    ...(params || {}),
                    locale: localStorage.getItem('locale') || 'fr'
                },
                headers: HttpClient.getHeader(),
            })
            .then(
                (res: AxiosResponse): Promise<T> =>
                    HttpClient.handleAxiosResponse<T>(res),
            )
            .catch(
                (err: AxiosError): Promise<T> =>
                    HttpClient.handleAxiosError<T>(err),
            );
    }

    static delete<T>(endpoint: string, data?: unknown): Promise<T> {
        return HttpClient.instance
            .delete(endpoint, {
                data,
                headers: HttpClient.getHeader(),
            })
            .then(
                (res: AxiosResponse): Promise<T> =>
                    HttpClient.handleAxiosResponse<T>(res),
            )
            .catch(
                (err: AxiosError): Promise<T> =>
                    HttpClient.handleAxiosError<T>(err),
            );
    }

    static sendFile<T>(endpoint: string, data?: unknown): Promise<T> {
        return HttpClient.instance
            .post(endpoint, data, {
                headers: {
                    ...HttpClient.getHeader(),
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(
                (res: AxiosResponse): Promise<T> =>
                    HttpClient.handleAxiosResponse<T>(res),
            )
            .catch(
                (err: AxiosError): Promise<T> =>
                    HttpClient.handleAxiosError<T>(err),
            );
    }

    private static handleAxiosResponse<T>(res: AxiosResponse<T>): Promise<T> {
        const { data } = res;

        return Promise.resolve<T>(data);
    }

    private static handleAxiosError<T>(err: AxiosError): Promise<T> {
        if (err?.response?.status === 401) {
            console.log("Invalid credentials");
        }

        return Promise.reject<T>("Something went wrong");
    }

    private static getHeader() {
        return {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        };
    }
}
