import { FooterDataType } from "services/footer";
import "./Footer.scss";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from 'react-router-dom'
import { VISION } from "constant";

type FooterPropsType = {
    data: FooterDataType;
};

export const Footer = (props: FooterPropsType): JSX.Element => {
    const location = useLocation();


    if (location.pathname === VISION) {
        return <></>;
    }

    return (
        <div className="footer">
            <div className="container">
                <div className="footer__wrapper">
                    <div className="footer__logo__section">
                        <div>
                            <a className="footer__logo" href="/">
                                <img src={props.data.logo} alt="logo" />
                            </a>
                        </div>
                        <div className="footer__socials">
                            {props.data.socials.map((r) => (
                                <a href={r.url} key={uuidv4()}>
                                    <img src={r.imageUrl} alt="" />
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="footer__navigation">
                        {props.data.menu.map((r) => (
                            <div className="footer__navigation__list" key={uuidv4()}>
                                {r.map((u) => (
                                    <a key={uuidv4()} href={u.url}>{u.text}</a>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="footer__copyright">
                    Copyright © 2001 - 2022 Astase. Tous droits réservés
                </div>
            </div>
        </div>
    );
};
