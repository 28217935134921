export const HOME = "/";
export const MASTERY = "/mastery";
export const STRATEGY = "/strategy";
export const RELIABILITY = "/reliability";
export const ULTRABACKUPHOME = "/ultra-backup-home";
export const VISION = "/vision";
export const PRICE = '/price';
export const WHITEMARK = '/white-mark';
export const HISTORY = '/history';
export const CONTACT = '/contact';
