import { TitleType, TextChildren } from "./types";

const tree = (item: TextChildren, classBold: string): any => {
    if (item.children) {
        return item.children.map((t) => tree(t, classBold));
    }

    if (item.bold) {
        return <span className={classBold}>{item.text}</span>;
    }

    return item.text
}

export const renderTitle = (title: TitleType, classBold: string) => {
    return title.map((item, i) => {
        return (
            <>
                {i >= 1 ? <br /> : <></>}
                {item.children.map((t) => tree(t, classBold))}
            </>
        );
    });
};
