import { useState } from "react";
import "./Header.scss";
import { ICON, renderTitle } from "utils";
import { HeaderDataType } from "services/header";
import { v4 as uuidv4 } from "uuid";

type HeaderPropsType = {
    data: HeaderDataType;
};

export const Header = ({ data }: HeaderPropsType): JSX.Element => {
    const [open, setOpen] = useState(false);

    const openSlice = () => setOpen(!open);

    const changeLanuage = (key: string) => {
        localStorage.setItem('locale', key);
        window.location.reload();
    };

    const activeLanguage = (key: string) => {
        if (localStorage.getItem('locale') === key) {
            return 'active';
        }

        return '';
    };

    const onRedirect = (link?: string) => {
        if (!link) {
            return;
        }

        window.location.href = link;
    };

    return (
        <>
            <div className="header">
                <button type="button" onClick={openSlice}>
                    {open ? ICON.times : ICON.threeDots}
                </button>
                <a className="header__logo" href="/">
                    <img src={data.logo} alt="logo" />
                </a>
                <div className="header__right__section">
                    <button type="button" className="header__demo__button" onClick={() => onRedirect(data.demoButtonUrl)}>
                        {ICON.rocket}
                        <span>{data.demoButtonText}</span>
                    </button>
                    <div className="header__lang__section">
                        <button
                            className={`${activeLanguage('fr')} header__lang__button`}
                            type="button"
                            onClick={() => changeLanuage('fr')}
                        >
                            FR
                        </button>
                        <span> / </span>
                        <button
                            type="button"
                            className={`${activeLanguage('en')} header__lang__button`}
                            onClick={() => changeLanuage('en')}
                        >
                            EN
                        </button>
                    </div>
                </div>
                {open ? (
                    <div className="header__slice">
                        <div className="container">
                            <div className="header__slice__content">
                                <div className="header__slice__left">
                                    <h1>
                                        {renderTitle(
                                            data.hero.title,
                                            "highlight-text-yellow",
                                        )}
                                    </h1>
                                    <div className="header__slice__row">
                                        {data.hero.item.map((i) => (
                                            <div
                                                className="header__slice__card"
                                                key={uuidv4()}
                                            >
                                                <div className="header__slice__card__image-box">
                                                    <img
                                                        src={i.imageUrl}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="header__slice__card__info">
                                                    {i.tag ? (
                                                        <div className="header__slice__card__tag">
                                                            NOUVEAU
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <h2 className="header__slice__card__title"  onClick={() => onRedirect(i.url)}>
                                                        {i.title}
                                                    </h2>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="header__slice__right">
                                    <a
                                        href={data.login.url}
                                        className="header__slice__login"
                                    >
                                        <div className="header__slice__login-bg">
                                            <img
                                                src={data.login.imageUrl}
                                                alt=""
                                            />
                                        </div>
                                        <h2>
                                            {renderTitle(data.login.title, "")}
                                        </h2>
                                    </a>
                                    <div className="header__slice__menu">
                                        <span className="header__slice__small-title">
                                            {data.menuTitle}
                                        </span>
                                        {data.menu.map((e) => (
                                            <a href={e.url} key={uuidv4()}>
                                                {e.text}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="header__slice__socials">
                                        <span className="header__slice__small-title">
                                            {data.socialTitle}
                                        </span>
                                        <div className="header__slice__socials__list">
                                            {data.socials.map((e) => (
                                                <a href={e.url} key={uuidv4()}>
                                                    <img
                                                        src={e.imageUrl}
                                                        alt=""
                                                    />
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
