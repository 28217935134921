/* eslint-disable react-hooks/exhaustive-deps */
import "./App.scss";
import { v4 } from "uuid";
import { Suspense, useEffect, useState } from "react";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import { Routes, Route } from "react-router-dom";
import { Router, routes } from "utils";
import { Header } from "components/Header/Header";
import { Footer } from "components/Footer/Footer";
import { getFooterInfo, type FooterDataType } from "services/footer";
import { getHeaderInfo, type HeaderDataType } from "services/header";

type MainInfoType = {
    header: undefined | HeaderDataType;
    footer: undefined | FooterDataType;
};

function App() {
    const [mainInfo, setMainInfo] = useState<MainInfoType>({
        footer: undefined,
        header: undefined,
    });

    useEffect(() => {
        Promise.all([getFooterInfo(), getHeaderInfo()]).then((res) => {
            setMainInfo({
                header: res[1],
                footer: res[0],
            });
        });
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('locale')) {
            localStorage.setItem('locale', 'fr');
        }
    }, []);

    return (
        <Suspense fallback={<LoadingPage />}>
            <Router>
                {mainInfo.header ? <Header data={mainInfo.header} /> : <></>}
                <Routes>
                    {routes.map((route) => (
                        <Route
                            key={v4()}
                            path={route.path}
                            element={<route.component />}
                        />
                    ))}
                </Routes>
                {mainInfo.footer ? <Footer data={mainInfo.footer} /> : <></>}
            </Router>
        </Suspense>
    );
}

export default App;
