import { HttpClient } from 'utils/httpClient';
import { FooterDataType, FooterResponseDataType } from './types';
import { getImage } from 'utils';

export const getFooterInfo = async (): Promise<FooterDataType> =>
	HttpClient.get<FooterResponseDataType>(`footer?populate[SocialBlock][populate][0]=SocialLink.icon&populate[logo][populate]=*&populate[MenuBlock][populate]=*`).then((res) => {
		const { data: { attributes } } = res;

        return {
            logo: getImage(attributes.logo),
            socials: attributes.SocialBlock.SocialLink.map((s) => ({
                url: s.url,
                imageUrl: getImage(s.icon),
            })),
            menu: [
                attributes.MenuBlock.MenuLink1,
                attributes.MenuBlock.MenuLink2,
                attributes.MenuBlock.MenuLink3,
            ],
        };
	});
